// @import "~bootstrap/scss/bootstrap";

.main {
  max-width: 100vw;
  overflow-x: hidden;
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-size: 25px;
      font-weight: 500;
      // color: black !important;
  
      margin: 1rem 0;
      a {
        color: black !important;
        text-decoration: none !important;

      }
    }
    span {
      a {
        color: black !important;
      }
    }
  }
}

