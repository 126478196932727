* {
  margin: 0;
  padding: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");

@import "./variable.scss";
body,
html {
  font-family: "Poppins", sans-serif !important;
  max-width: 100vw !important;
  transition: all 300ms ease-in-out !important;
  overflow-x: hidden !important;
  background-color: $secondaryWhite;
  scroll-behavior: smooth !important;
}

.top {
  position: fixed;

  background-color: $primaryColor !important;
  width: 40px !important;
  height: 40px !important;
  color: $secondaryWhite;
  display: grid;
  place-items: center;
  bottom: 20px;
  right: 20px !important;
  z-index: 100;
  cursor: pointer;
  border: none;
  border-radius: 100rem;
  transition: all 500ms ease-in-out;

  svg {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transition: all 500ms ease-in-out;
  }
}

#root {
  width: 100vw;
}

.heading {
  h1 {
    font-size: 25px;
    font-weight: 500;
    color: $secondaryBlack;
    transition: all 500ms ease-in-out !important;

    margin: 1rem 0;
    span {
      color: $primaryColor;
      transition: all 500ms ease-in-out !important;
    }
  }
}
