.main {
   padding: 2% 5%; 
  
}

.main-container {
   min-height: 100vh;


   // .anime-container {
   //    min-height: 100vh !important;
   // }
   // .asian-container {
   //    min-height: 100vh !important;
   // }
}




