@import "../../variable.scss";

.recommend {
  // width: 100vw;
  // padding:0 5%;

  .heading {
    padding: 0% 5%;

    h1 {
      font-size: 25px;
      font-weight: 500;

      margin: 1rem 0;
      span {
        
        color: $primaryColor;
        
        
        
        
      }
    }
  }
  .recommend-container {
    display: grid;
    grid-template-columns: 150px 150px;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    padding: 0% 5%;

    // justify-content: center;

    .card {
      margin-bottom: 1rem;

      a {
        text-decoration: none;
        color: $secondaryBlack;
        .image-container {
          height: 220px !important;
          position: relative !important;
          img {
            width: 100%;

            height: 220px !important;
            border-radius: 6px;
            object-fit: cover;
          }
        }

        .title {
          font-size: 14px;

          margin-bottom: 0.5rem;
          
        }
        span {
          color: $secondaryGray;
          font-size: 13px;
        }
      }
    }
  }
  @media screen and (max-width: 1000px) {
    width: 100vw !important;
    position: relative;

    .recommend-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      justify-content: center;

      .card {
        a {
      .image-container {
        height: 230px !important;
            span {
              img {
                height: 230px !important;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .recommend-container {
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      .card {
        a {
      .image-container {
        height: 200px !important;
            span {
              img {
                height: 200px !important;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 425px) {
    .recommend-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      justify-content: center;
      .card {
        a {
      .image-container {
        height: 240px !important;
            span {
              img {
                height: 240px !important;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 325px) {
    .recommend-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      justify-content: center;
      .card {
        a {
      .image-container {
        height: 200px !important;
            span {
              img {
                height: 200px !important;
              }
            }
          }
        }
      }
    }
  }
}
