$primaryColor:rgb(40, 172, 40);
$secondaryBlack:black;
$secondaryWhite:white;
$secondaryGray:gray;
$primary-watching:rgb(40, 172, 40);
$primary-nav-background:  rgb(40, 172, 40);
$primary-nav-back-hover:rgb(12, 102, 12);
$primary-hamburger-menu:rgb(78, 78, 78);


