@import "../../variable.scss";

.A-watching {
  .nav-links-wrapper {

    .nav-links {

      display: none !important; 
    }
  }
}

.watching {
  display: flex;
  padding: 1% 5%;
  position: relative;
  max-width: 100vw !important;

  .left-container {
    flex: 2;
    margin: 0 20px;

    .watching-wrapper {
      h1 {
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
  .right-container {
    flex: 1;
    // display: grid;
    // place-content: center;
  }
  @media screen and (max-width: 1000px) {
    .right-container {
      flex: 1;
      display: grid;
      place-content: center;
    }
  }
}

.watching-container {
  .heading {
    p {
      font-size: 13px;
      margin: 1rem 0;

      span {
        color: $primaryColor;
      }
      a {
        text-decoration:none !important;
        color: $secondaryBlack;
      }
    }
  }
  .card {
    transition: all 500ms ease-in-out;
    h1 {
      font-size: 24px !important;
      margin: 1rem 0;
      font-weight: 400;
    }
    p {
      font-size: 13px;
      margin: 1rem 0;
      span {
        font-size: 15px;
        color: $primary-watching;
      }
    }

    .page-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 500ms ease-in-out;
      .inner-btn {
       
        
      
        margin: 15px 12px 0 12px;
        align-self: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        position: relative;
        transition: all 500ms ease-in-out;
        &:hover {
          
          p {
            display: block;
            transition: all 500ms ease-in-out;
          }
        }
        a {
          
          button {
            border:none;
            background-color: $primaryColor;
            align-self: center;
            justify-content: center;
            align-items: center;
            display: flex;
            border-radius: 60px;
            width: 38px;
            height:38px;
            transition: all 500ms ease-in-out;
            cursor: pointer;
            
            

          }
          button:disabled {
            cursor:not-allowed;
          }
        }
          p {
            position: absolute;
            top: 30px;
            left:3px;
            background-color: $secondaryBlack;
            color:$secondaryWhite;
            padding:2px 5px;
            opacity:0.7;
            border-radius: 4px;
            font-size: 11px;
            display: none;
            transition: all 500ms ease-in-out;
          }
         
      }
    }
   
  }

  nav {
display: none !important;
  }
  .detail {
    .left-container {
      margin: 0;
      .detail-container {
        .wrapper {
          padding: 0;
          .card {
            display: none !important;

          }
        }

      }
    }
  }
  .footer {
    display: none !important;
  }
}

.drama-detail {
  .card {
    display: flex;
    gap: 2rem;
    .image {
      img {
        width: 240px !important;
        height: 320px;
        border-radius: 6px;
        object-fit: cover;
      }
    }
    p {
      margin: 1rem 0;
    }
  }
}
#iframe-container {
  width: 100%;
  height: 100%;
 

  @media screen and (max-width: 2500px) {
    height: 720px !important;
  }
  @media screen and (max-width: 1700px) {
    height: 472px !important;
  }
  @media screen and (max-width: 1200px) {
    height: 480px !important;
  }
  @media screen and (max-width: 1000px) {
    height: 560px !important;
  }
  @media screen and (max-width: 900px) {
    height: 480px !important;
  }
  @media screen and (max-width: 800px) {
    height: 440px !important;
  }
  @media screen and (max-width: 750px) {
    height: 400px !important;
  }
  @media screen and (max-width: 700px) {
    height: 360px !important;
  }
  @media screen and (max-width: 650px) {
    height: 340px !important;
  }
  @media screen and (max-width: 600px) {
    height: 310px !important;
  }
  @media screen and (max-width: 550px) {
    height: 282px !important;
  }
  @media screen and (max-width: 500px) {
    height: 260px !important;
  }
}

@media screen and (max-width: 1000px) {
  .watching {
    display: block;

    padding: 1%;

    .right-container {
      padding: 1% 5%;
    }
  }
}
@media screen and (max-width: 800px) {
  .watching {
    display: block;

    padding: 1%;

    .right-container {
      padding: 1% 5%;
    }
  }
}
.server-notice {
  background-color: $primaryColor;
  border-radius: 5px !important;
  p {
    padding: 10px ;
    font-size: 13px !important;
    color: $secondaryWhite;
    
  }

  @media screen and (max-width:800px) {
    p {
      font-size: 10px !important;
    }
    
  }
}