

@import "../../../variable.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");
.episodes {
  .heading {
    h1 {
      margin: 1rem 0 !important;
      font-weight: 500 !important;
    }
  }
  .ep {
    .hidden-wrapper {
      display: none !important;
    }
  }
  
  .List {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(150px ,1fr));

   @media screen and (max-width:800px) {
    grid-template-columns: repeat(auto-fill, minmax(120px ,1fr));
   }
   
    gap: 1rem;
    
    .heading {
      margin: 1rem 0.5rem;
    }
    .list-item {
      list-style-type: none;
      margin-bottom: 1.3rem;
      // border: 2px solid $secondaryGray !important;
      //  border-radius: 7px;

      a {
        text-decoration: none;
        color: $secondaryBlack;
        &:hover {
          color: $primary-watching;
        }

        .image-container {
          width: 100%;
          height: 80px;
          position: relative;


          .tag {
            position: absolute;
            bottom: 8px;
            background-color: red;
            font-size:9px;
            padding: 2px 4px;
            left: 8px;
            color: $secondaryWhite;
            font-weight: 400;
            border-radius: 3px;
          }
         

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
          }
        }
        .ep-flex {
          padding: 0.6rem;
          
          
         
          font-weight: 500;
          display: flex;
          // flex-direction: column;
          font-size: 12px;
          // text-align: center !important;

          span {
            margin-left: 4px;
          }
        }
      }
      .active {
        color: $secondaryWhite;
        
        .image-container {
          
          img {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          }
        }
        .ep-flex {
          background-color: $primary-watching !important;
          // border: 2px solid $primary-watching !important;
          // border: none !important;
          border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          
          
        }
        &:hover {
          color: $secondaryWhite !important;
        }
      }
    }

    // .md {
    //   display: grid !important;
    //   grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important ;
    //   gap: 1rem;
    //   // border: 1px solid red;
    //   // place-content: center;
    //   // padding-left: 2% ;

    //   li {
    //     // display: grid;
    //     // place-items: center;
    //     a {
    //       .ep-flex {
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
            

    //         border: 2px solid $secondaryGray !important;
            
            
    //       }
    //       // .ani-ep {
    //       //   p {

    //       //     font-size: 18px !important;
    //       //   }
    //       // }
    //     }

    //     .active {
    //       .ep-flex {
    //         background-color: $primary-watching !important;
    //         border: 1.5px solid $primary-watching !important
    //       }
    //     }
    //   }
    // }

    @media screen and (max-width: 600px) {
      padding:0 3%;
      li {
        a {
          .ep-flex {
            font-size: 14px !important;

            // padding: 0.6rem 0.8rem;
            span {
              margin-left: 4px !important;
            }
          }
        }
      }
    }
    @media screen and (max-width: 600px) {
      .lg {
        display: none !important;
      }
    }
    @media screen and (min-width: 600px) {
      .md {
        display: none !important;
      }
    }
  }
  // .unorder-list {
  //   flex-direction: column !important;
  // }
}

.episodes-wrapper {

  .episode-search {
    margin-bottom: 1rem;
    position: relative;

    input {
      border: 2px solid $primaryColor !important;
      outline: none !important;
      padding: 0.5rem;
      width: 95%;
      height: 25px;
     
      border-radius: 100rem;

      &::placeholder {
        font-size: 15px;
        margin-left: 2rem;
        font-family: "Poppins", sans-serif !important;
      }
  
    }
    .ep-search {
      position: absolute;
      top: 10px;
      right: 4.5%;

      svg {
        font-size: 25px;
        color: $secondaryGray;
      }
    }
  }
}