@import "../../variable.scss";

.intro-page {
  // text-align: center;
  // justify-items: center;
  // margin-top: 5%;
  // padding: 0% 10%;
  max-width: 100vw;
  transition: all 500ms ease-in-out;
  overflow: hidden;
  // background-image: url("../../assets//overview.png");
}
.top-background {
  padding-top: 15%;
  position: relative;
  z-index: 2;
  border: none;
  // background-color: black;
  transition: all 500ms ease-in-out;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    // background-color: black;
    background: linear-gradient(
        0deg,
        $secondaryWhite 5%,
        rgba(104, 103, 103, 0) 100%
      ),
      url("../../assets/back.png") repeat-x !important;
    background-position: center;
    background-size: contain !important;
    background-repeat: no-repeat;
    background-attachment: scroll;
    width: 5076px;
    height: 100%;
    z-index: -2;
    opacity: 0.9;
    padding: 0;
    margin: 0;
    transition: all 500ms ease-in-out;
    animation: scroll 90s linear infinite forwards;
  }
}
.top-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 20;
  transition: all 500ms ease-in-out;

  .logo {
    img {
      width: 280px;
      height: 90px;
    }
  }
  .notice {
    margin: 0.5rem 0;
    text-align: center;
    transition: all 500ms ease-in-out;
    h1 {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .search-consoule {
    margin: 0.5rem 0;
    width: 60vw;
    transition: all 500ms ease-in-out;

    .search-input {
      box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
        rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
      animation: none !important;
      width: 100%;
      border-radius: 5px !important;

      .main {
        box-shadow: none;
      }

      .btn {
        height: 45px !important;
        animation: none !important;
        a {
          svg {
            animation: none !important;
          }
        }
      }
    }
  }
}
.home-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3rem 0;
  transition: all 500ms ease-in-out;

  button {
    background-color: $primaryColor;
    outline: none;
    border: none;
    padding: 0.8rem 1rem;
    border-radius: 6px;
    color: $secondaryWhite;
    cursor: pointer;
    transition: all 500ms ease-in-out;

    h3 {
      font-size: 18px;
      font-weight: 400;
      transition: all 500ms ease-in-out;
    }
    &:hover {
      transform: scale(1.1);
      transition: all 500ms ease-in-out;
    }
  }
}
.site-text {
  margin: 12px 0;
  transition: all 500ms ease-in-out;
  padding: 0% 5%;
  h1 {
    font-size: 30px;
    margin: 12px 0;
    font-weight: 600;
    color: rgb(68, 67, 67);
    transition: all 500ms ease-in-out;
  }
}
@media screen and (max-width: 800px) {
  // .intro-page {
  //     padding: 0% 5%;
  // }
  // .top-background {
  //     padding-top: 35% ;
  //     transition: all 500ms ease-in-out;
  //     &::after {

  //         background: linear-gradient(0deg, $secondaryWhite 5%, rgba(104, 103, 103, 0) 10%)  ,  url("../../assets/back.png") repeat-x !important;
  //     background-position: center;
  //     background-size: contain !important;
  //     background-repeat: no-repeat ;
  //     background-attachment: scroll;
  //     animation: scroll 90s linear infinite forwards;
  //     transition: all 500ms ease-in-out;

  //     }
  // }
  .top-wrapper {
    padding: 0% 5%;
    transition: all 500ms ease-in-out;
    .notice {
      h1 {
        font-size: 12px;
      }
    }
    .search-consoule {
      width: 90vw;
    }
  }
  .site-text {
    padding: 0% 5%;
    transition: all 500ms ease-in-out;

    h1 {
      font-size: 20px;
      margin: 12px 0;
      font-weight: 600;
    }
    p {
      font-size: 12px;
      width: 90vw;
    }
  }
  .home-btn {
    margin: 4rem 0;
  }
}
@keyframes scroll {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1692px, 0, 0); /* The image width */
  }
}
